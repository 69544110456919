import React from "react"
import Project from "./project"
import { arrayOf, shape, ProjectType } from "@wkocjan/gatsby-theme-intro/src/types"
import { Link } from 'gatsby';

const Projects = ({ projects }) => {

  const mainProjects = projects.slice(0, 1);
  const sideProjects = projects.slice(1);

  return (
    <>
      {mainProjects && (
        <>
          <h5 className="font-header font-semibold text-front uppercase mb-3">
            Projects
          </h5>
          <div className="flex flex-wrap mb-4">
            {mainProjects.map((project, i) => (
              <Project key={`${project.name}_${i}`} {...project} />
            ))}
          </div>
        </>
      )}
      {sideProjects && (
        <>
          <h5 className="font-header font-semibold text-front uppercase mb-3">
            Side projects
          </h5>
          <div className="flex flex-wrap mb-4">
            {sideProjects.map((project, i) => (
              <Project key={`${project.name}_${i}`} {...project} />
            ))}
          </div>
        </>
      )}
      <p>For other resources and content see <Link to="/content/resources">Resources section</Link></p>
    </>
  )
};

Projects.propTypes = {
  projects: arrayOf(shape(ProjectType)),
};

export default Projects
