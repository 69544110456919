import React from "react"
import { Link } from "gatsby"
import { ProjectType } from "@wkocjan/gatsby-theme-intro/src/components/projects/../../types"
import ProjectIcon from "@wkocjan/gatsby-theme-intro/src/components/projects/project-icon"
import ProjectStatus from "@wkocjan/gatsby-theme-intro/src/components/projects/project-status"
import ProjectTags from "@wkocjan/gatsby-theme-intro/src/components/projects/project-tags"

const Project = props => {
  const { name, url, description, status, tags, icon } = props
  return (
    <div className="w-full sm:w-1/2 mb-2">
      <div className="h-full flex flex-col justify-between relative bg-back-light p-4 lg:p-4 bg-no-repeat m-1">
        <div className="lg:flex-1">
          <h4 className="font-bold"><Link to={url}>{name}</Link></h4>
          {url && (
            <Link
              className="text-front underline hover:opacity-75 transition-opacity duration-150"
              to={url}
            >
              {url}
            </Link>
          )}
          <p className="w-full py-4 whitespace-pre-line">{description}</p>
        </div>
          <ul className="pr-2 text-xs">
            {status && <ProjectStatus status={status} />}
            {tags && <ProjectTags tags={tags} />}
          </ul>

          {icon && <ProjectIcon icon={icon} />}

      </div>
    </div>
  )
}

Project.propTypes = ProjectType

export default Project
