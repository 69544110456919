import React from "react"
import About from "@wkocjan/gatsby-theme-intro/src/components/about/about"
import ContactForm from "@wkocjan/gatsby-theme-intro/src/components/contact-form/contact-form"
import Projects from "@wkocjan/gatsby-theme-intro/src/components/projects/projects"
import Summary from "@wkocjan/gatsby-theme-intro/src/components/summary/summary"

const MainContent = ({ projects, profile }) => {
  return (
    <main className="w-full lg:w-2/3 lg:pl-8 xl:pl-12">
      {profile.about && <About about={profile.about} />}
      <Summary profile={profile} />
      {projects && <Projects projects={projects} />}
    </main>
  )
};

export default MainContent
